<template>
  <div>
    <b-row>
      <b-col class="m-3">
        <label for="search"> Cari Pelanggan:</label>
          <b-form-input v-model="filter" type="search" placeholder="Search"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-table :filter="filter" striped hover :items="$store.state.users" :fields="fields">
        <template v-slot:cell(actions)="data">
          <b-button variant="info" @click="viewDetail(data.item.id)">
            Detail</b-button
          >
        </template>
        <template v-slot:cell(updated)="data">
          {{ formatDate(data.item.updatedAt) }}
        </template>
      </b-table>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  methods: {
    viewDetail(id) {
      this.$router.push({ name: "User", params: { userId: id } });
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
  },
  mounted() {
    this.$store.dispatch("fetchMTPSUsers");
  },
  data() {
    return {
      fields: [
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "phoneNumber",
          label: "Phone Number",
          sortable: true,
        },
        {
          key: "fullName",
          label: "Nama Lengkap",
          sortable: true,
        },
        // {
        //   key: "status",
        //   label: "Status",
        //   sortable: true,
        // },
        {
          key: "updated",
          label: "Update",
          sortable: true,
        },
        {
          key: "actions",
          label: "Action",
          sortable: false,
        },
      ],
      filter: "",
    };
  },
};
</script>

<style>
</style>